/**
 * 字符串工具类
 * @author HuangJun
 */

/**
 * @description 填充0
 * @param {Number} maxLength 最大位数
 * @param {any} val 需要填充0的字符串
 * @return String
 */
export const fillZero = (maxLength, val) => {
  val = String(val).replace(/[^0-9]/gi, '');
  val = Number(val) + 1 + '';
  for (let len = val.length; len < maxLength; len = val.length) {
    val = '0' + val;
  }
  return val;
};
