<template>
  <el-table style="width: 1000px" :data="mdrlForm.mdrl_info_list" border>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">供应商</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mdrl_info_list.' + scope.$index + '.supp_id'" :rules="[{ required: true }]">
          <el-select
            filterable
            @change="changegetSuppsList(scope.row, scope.$index)"
            v-model="mdrlForm.mdrl_info_list[scope.$index].supp_id"
            placeholder="请选择打样部门"
            clearable
          >
            <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料单价</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mdrl_info_list.' + scope.$index + '.mdrl_price'" :rules="[{ required: true }]">
          <el-input
            @input="inputChange(scope.row, scope.$index)"
            v-model="mdrlForm.mdrl_info_list[scope.$index].mdrl_price"
            @blur="
              mdrlForm.mdrl_info_list[scope.$index].mdrl_price = helper.calcPrice(
                mdrlForm.mdrl_info_list[scope.$index].mdrl_price,
                4,
                1000000
              )
            "
            maxlength="11"
            show-word-limit
            placeholder="请填写物料单价"
          >
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column width="150" align="center" label="设为默认商户">
      <template v-slot="scope">
        <el-checkbox
          @change="checkboxChange(scope.row, scope.$index)"
          v-model="mdrlForm.mdrl_info_list[scope.$index].mdrl_info_checked"
        ></el-checkbox>
      </template>
    </el-table-column>
    <el-table-column width="120" align="center" label="操作">
      <template v-slot="scope">
        <el-link :disabled="isShow" type="primary" class="vg_cursor" @click="deleteClick(scope.row)">删除</el-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { debounce } from 'lodash';

export default {
  name: 'SmplEditBomChild',
  props: {
    mdrlForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      mdrlFlag: 0,
      selectFlag: false,
      suppType: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getSuppsList();
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1).then(res => {
        if (res.data.code === 0) {
          this.suppType = res.data.data;
        }
      });
    },
    // 修改下拉框
    changegetSuppsList(val, val1) {
      if (val.mdrl_info_checked) {
        this.$emit('selectChange', val1);
      }
    },
    // 修改价格
    inputChange: debounce(function (val, index) {
      if (val.mdrl_info_checked) {
        this.$emit('inputChange', index);
      }
    }, 500),
    // 删除
    deleteClick(val) {
      this.$emit('deleteClick', val);
    },
    // 更改复选框
    checkboxChange(val, val1) {
      let temp = JSON.parse(JSON.stringify(val));
      this.$emit('checkboxChange', temp, val1);
    }
  }
};
</script>

<style scoped></style>
