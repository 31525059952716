<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('mdrlForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-row>
      <el-col :md="8" :offset="7" v-if="warningTitle">
        <div class="vd_color_red">！{{ warningTitle }}</div>
      </el-col>
    </el-row>
    <el-form ref="mdrlForm" :model="mdrlForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="6">
          <el-form-item label="材料图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mdrlForm.imge_id" :imgeUrl="mdrlForm.imge_url" :imgeUrlO="mdrlForm.imge_url"></imgPopover>

              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer" />
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="材料编号" prop="mdrl_no" :rules="[{ required: true }]">
            <el-input
              v-model="mdrlForm.mdrl_no"
              maxlength="30"
              show-word-limit
              placeholder="请填写材料编号"
              @change="fillZero"
              onkeyup="this.value = this.value.replace(/[^A-Za-z]/g,'').toUpperCase().trim()"
            >
              <!--TODO 计算位数-->
              <template slot="append">{{ mdrlForm.mdrl_no_suffix }}</template>
            </el-input>
          </el-form-item>
          <el-form-item label="材料名称" prop="mdrl_name" :rules="[{ required: true }]">
            <el-input v-model="mdrlForm.mdrl_name" maxlength="30" show-word-limit placeholder="请填写材料名称"> </el-input>
          </el-form-item>
          <el-form-item label="材料类型" prop="mdrl_type" :rules="[{ required: true }]">
            <el-select v-model="mdrlForm.mdrl_type" placeholder="请选择材料类型" clearable>
              <el-option v-for="item in mdrlType" :key="item.label + item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="材料规格" prop="mdrl_spec" v-if="mdrlForm.mdrl_type !== 1">
            <el-input v-model="mdrlForm.mdrl_spec" show-word-limit placeholder="请填写材料规格" />
          </el-form-item>
          <el-form-item label="材料单位" prop="mdrl_unit">
            <el-select v-model="mdrlForm.mdrl_unit" placeholder="请选择材料单位" clearable>
              <el-option v-for="item in mdrlUnitList" :key="item.id" :label="item.label" :value="item.label"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="材料材质" prop="mdrl_qual">
            <el-select v-if="mdrlForm.mdrl_type === 1" v-model="mdrlForm.mdrl_qual" placeholder="请填写材料材质" clearable>
              <el-option v-for="item in mainMaterial" :key="item.label + item.id" :label="item.label" :value="item.label"> </el-option>
            </el-select>
            <el-select v-else-if="mdrlForm.mdrl_type === 2" v-model="mdrlForm.mdrl_qual" placeholder="请填写材料材质" clearable>
              <el-option v-for="item in minorMaterial" :key="item.label + item.id" :label="item.label" :value="item.label"> </el-option>
            </el-select>
            <el-input v-else v-model="mdrlForm.mdrl_qual" maxlength="30" show-word-limit placeholder=""> </el-input>
          </el-form-item>
          <el-form-item label="材料重量" prop="mdrl_weight">
            <el-input v-model="mdrlForm.mdrl_weight" show-word-limit placeholder="请填写材料重量">
              <template slot="append">公斤</template>
            </el-input>
          </el-form-item>
          <el-form-item label="材料单价" prop="mdrl_price">
            <el-input disabled v-model="mdrlForm.mdrl_price" maxlength="11" show-word-limit placeholder="请填写材料单价">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="材料不含税单价" prop="no_mdrl_price">
            <el-input
              v-model="mdrlForm.no_mdrl_price"
              @input="mdrlForm.no_mdrl_price = helper.keepTNum1(mdrlForm.no_mdrl_price)"
              @blur="mdrlForm.no_mdrl_price = helper.calcPrice(mdrlForm.no_mdrl_price, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写材料不含税单价"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商户" prop="supp_id">
            <el-select filterable v-model="mdrlForm.supp_id" placeholder="请选择供应商" clearable>
              <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="安全库存" prop="mdrl_stock_limit" :rules="[{ required: true }]">
            <el-input v-model="mdrlForm.mdrl_stock_limit" maxlength="30" show-word-limit placeholder="请填写安全库存"> </el-input>
          </el-form-item>
          <el-form-item label="其他备注" prop="mdrl_remark">
            <el-input
              type="textarea"
              rows="1"
              v-model="mdrlForm.mdrl_remark"
              maxlength="150"
              show-word-limit
              placeholder="请填写材料备注"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <el-row>
        <el-col :md="7">
          <div class="vd_hidden">111</div>
        </el-col>
        <el-col :md="16">
          <div>
            <div class="vd_but vg_mb_8">
              <el-button plain size="small" @click="addRow()">新增</el-button>
            </div>
            <MdrlImport
              @checkboxChange="checkboxChange"
              @inputChange="inputChange"
              @selectChange="selectChange"
              @deleteClick="deleteClick"
              :mdrlForm="mdrlForm"
              :isShow="true"
            ></MdrlImport>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="mdrlForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdrlAPI } from '@api/modules/mdrl';
import { optnAPI } from '@api/modules/optn';
import { suppAPI } from '@api/modules/supp';
import { imgeAPI } from '@api/modules/imge';
import { colrAPI } from '@api/modules/colr.js';
import addHeader from '@/views/component/addHeader.vue';
import helper from '@assets/js/helper';
import inputUser from '@/views/component/inputUser.vue';
import imgPopover from '@/views/component/imgPopover.vue';
import MdrlImport from './Componet/MdrlImport.vue';
import { fillZero } from '@assets/js/StringUtils';
import { cloneDeep } from 'lodash';

export default {
  name: 'mdrlAddMain',
  components: {
    addHeader,
    inputUser,
    imgPopover,
    MdrlImport
  },
  data() {
    return {
      mdrlForm: {
        mdrl_no: '',
        mdrl_name: '',
        mdrl_type: 1,
        mdrl_spec: '',
        mdrl_weight: '',
        mdrl_unit: '',
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        mdrl_stock_limit: 0,
        mdrl_remark: '',
        cust_id: null,
        cust_name: null,
        supp_id: '',
        user_id: null,
        stff_id: null,
        dept_id: null,
        status: 0,
        key: 0,
        mdrl_info_list: [
          {
            supp_id: null,
            mdrl_price: 0,
            mdrl_info_checked: true,
            key: 0
          }
        ],
        mdrl_no_suffix: '',
        no_mdrl_price: 0,
        mdrl_price: 0
      },
      warningTitle: '',
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ],
      mainMaterial: [
        { id: 0, label: 'P20精料' },
        { id: 1, label: 'P20毛料' },
        { id: 2, label: 'P20光料' },
        { id: 3, label: '45#精料' },
        { id: 4, label: '45#光料' },
        { id: 5, label: '45#毛料' },
        { id: 6, label: '738H精料' },
        { id: 7, label: '718H精料' }
      ],
      minorMaterial: [
        { id: 0, label: 'SUJ2' },
        { id: 1, label: '20CR' },
        { id: 2, label: '镀铜' },
        { id: 3, label: '45#' },
        { id: 4, label: 'STD' }
      ],
      mdrlTypeGroupOpt: [],
      suppType: [],
      optnColorList: [],
      checkedIndex: 0,
      MdrlFlag: false,
      CustTitle: '添加物料专属客户',
      imgTypeFlag: true,
      stffForm: {},
      mdrlUnitList: [
        { id: 1, label: '件' },
        { id: 2, label: '个' },
        { id: 3, label: '支' },
        { id: 4, label: '条' }
      ]
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getMdrlType();
      this.getSuppsList();
    },
    fillZero(val) {
      mdrlAPI.getMdrlNoTopMax({ mdrlNo: val }).then(({ data }) => {
        if (data.code !== 0) {
          this.mdrlForm.mdrl_no_suffix = '00001';
          return;
        }
        this.mdrlForm.mdrl_no_suffix = fillZero(5, data.data.mdrlNo);
      });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.saveInfo();
          } else {
            console.log('error submit!');
            this.$message.warning('请把必填内容填写完整！');
            return false;
          }
        });
      });
    },
    // 保存
    saveInfo() {
      let mdrlForm = cloneDeep(this.mdrlForm);
      for (let i in mdrlForm.mdrl_info_list) {
        if (mdrlForm.mdrl_info_list[i].mdrl_info_checked) {
          mdrlForm.mdrl_info_list[i].mdrl_info_checked = 1;
        } else if (!mdrlForm.mdrl_info_list[i].mdrl_info_checked) {
          mdrlForm.mdrl_info_list[i].mdrl_info_checked = 0;
        }
      }
      mdrlForm.mdrl_no = mdrlForm.mdrl_no.toLocaleUpperCase() + mdrlForm.mdrl_no_suffix;
      post(mdrlAPI.addMdrl, mdrlForm).then(res => {
        if (res.data.code === 0) {
          this.$message({ type: 'success', message: '保存成功' });
          const permId = this.$route.query.perm_id;
          this.jump(`/mdrl_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`);
          this.warningTitle = false;
          this.resetForm('mdrlForm');
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('mdrlForm');
        this.$router.go(-1);
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      });
    },
    //打开专属客户弹窗
    custImport() {
      this.MdrlFlag = true;
    },
    //关闭专属客户弹窗
    custCancel() {
      this.MdrlFlag = false;
    },
    // custID子传父值
    custID(val) {
      if (val) {
        this.mdrlForm.cust_id = val.join(',');
      } else {
        this.mdrlForm.cust_id = null;
      }
    },
    custName(val) {
      if (val) {
        this.mdrlForm.cust_name = val.join(',');
      } else {
        this.mdrlForm.cust_name = null;
      }
    },
    // 取消
    cancelCust() {
      this.MdrlFlag = false;
    },
    // 新增字表数据
    addRow() {
      let temp = {};
      if (this.mdrlForm.supp_id) {
        temp.supp_id = this.mdrlForm.supp_id;
      } else {
        temp.supp_id = null;
      }
      if (this.mdrlForm.mdrl_price) {
        temp.mdrl_price = this.mdrlForm.mdrl_price;
      } else {
        temp.mdrl_price = '0.0000';
      }
      temp.mdrl_info_checked = false;
      temp.key = this.mdrlForm.mdrl_info_list.length;
      this.mdrlForm.mdrl_info_list.push(temp);
    },
    // 删除子表数据
    deleteClick(val) {
      if (this.mdrlForm.mdrl_info_list.length <= 1) {
        this.$message({
          type: 'warning',
          message: '至少保留一条'
        });
      } else {
        let flag = false;
        if (this.mdrlForm.mdrl_info_list[val.key].mdrl_info_checked) {
          flag = true;
        }
        this.mdrlForm.mdrl_info_list.splice(val.key, 1);
        for (let i in this.mdrlForm.mdrl_info_list) {
          this.mdrlForm.mdrl_info_list[i].key = i;
        }
        if (flag) {
          this.mdrlForm.mdrl_info_list[0].mdrl_info_checked = true;
          this.checkedIndex = 0;
          this.mdrlForm.mdrl_price = this.mdrlForm.mdrl_info_list[0].mdrl_price;
          this.mdrlForm.supp_id = this.mdrlForm.mdrl_info_list[0].supp_id;
        } else {
          for (let i in this.mdrlForm.mdrl_info_list) {
            if (this.mdrlForm.mdrl_info_list[i].mdrl_info_checked) {
              this.checkedIndex = i;
            }
          }
        }
      }
    },
    // 狭隘拉框更换
    selectChange(val) {
      this.mdrlForm.supp_id = this.mdrlForm.mdrl_info_list[val].supp_id;
    },
    // 价格变动
    inputChange(index) {
      this.mdrlForm.mdrl_info_list[index].mdrl_price = helper.keepTNum1(this.mdrlForm.mdrl_info_list[index].mdrl_price);
      this.mdrlForm.mdrl_price = helper.haveFour(this.mdrlForm.mdrl_info_list[index].mdrl_price);
    },
    // 更换复选框
    checkboxChange(val, val1) {
      if (val.mdrl_info_checked) {
        let flag = 0;
        for (let i in this.mdrlForm.mdrl_info_list) {
          if (this.mdrlForm.mdrl_info_list[i].mdrl_info_checked) {
            flag += 1;
          }
        }
        if (flag > 1) {
          let ind = JSON.parse(JSON.stringify(this.checkedIndex));
          this.mdrlForm.mdrl_info_list[ind].mdrl_info_checked = false;
          this.checkedIndex = val1;
          this.mdrlForm.mdrl_price = this.mdrlForm.mdrl_info_list[val1].mdrl_price;
          this.mdrlForm.supp_id = this.mdrlForm.mdrl_info_list[val1].supp_id;
        } else {
          this.checkedIndex = val1;
          this.mdrlForm.mdrl_price = this.mdrlForm.mdrl_info_list[val1].mdrl_price;
          this.mdrlForm.supp_id = this.mdrlForm.mdrl_info_list[val1].supp_id;
        }
      } else {
        let flag = 0;
        for (let i in this.mdrlForm.mdrl_info_list) {
          if (this.mdrlForm.mdrl_info_list[i].mdrl_info_checked) {
            flag += 1;
          }
        }
        if (flag === 0) {
          this.mdrlForm.mdrl_info_list[val1].mdrl_info_checked = true;
          this.mdrlForm.mdrl_price = this.mdrlForm.mdrl_info_list[val1].mdrl_price;
          this.mdrlForm.supp_id = this.mdrlForm.mdrl_info_list[val1].supp_id;
          this.checkedIndex = val1;
        }
      }
    },
    // 商户下拉框下拉
    suppChange() {
      this.getSuppsList();
    },
    // 获取单位
    getMdrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mdrlTypeGroupOpt = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1).then(res => {
        if (res.data.code === 0) {
          this.suppType = res.data.data;
        }
      });
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        if (this.imgTypeFlag) return this.$message.warning('仅支持jpg、png、jpeg格式的图片');
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mdrlForm.imge_id = res.data.imge_id;
        this.mdrlForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.mdrlForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      this.imgTypeFlag = false;
      let str = res.name.substr(res.name.indexOf('.') + 1, res.name.length);
      let imgTypeList = ['jpg', 'png', 'jpeg'];
      if (imgTypeList.indexOf(str) === -1) this.imgTypeFlag = true;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mdrlForm.imge_url = null;
      this.mdrlForm.imge_urlO = null;
      this.mdrlForm.imge_id = null;
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_cussor {
  cursor: default;
}
::v-deep .mdrlNo .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .mdrlcolor .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .el-input-group__append {
  color: #000000;
}
.vd_color_red {
  color: red;
}
::v-deep .itemBtn .el-form-item__content {
  display: flex;
}

.vd_solidDiv {
  height: 1px;
  border: none;
  border-top: 1px dashed #dcdfe6;
  // background-color: #DCDFE6;
  margin: 10px 0 20px 0;
}
.vd_hidden {
  visibility: hidden;
}
</style>
